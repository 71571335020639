import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from './store/global/actions';
import Sidebar from './theme/Sidebar'
import Topbar from './theme/Topbar'
import ErrorBoundary from './components/ErrorBoundary';
import Login from './pages/frontend/Login';
import Register from './pages/frontend/Register';
import FrontendError404 from './pages/frontend/FrontendError404';
import Password from './pages/frontend/Password';
import NoCompleteUserdata from './pages/userbackend/preforms/NoCompleteUserdata';
import AdminTopbar from './theme/admin/AdminTopbar';
import AdminSidebar from './theme/admin/AdminSidebar';
import HelperService from './services/helper.service';
import UserEndMembership from './pages/frontend/UserEndMembership';

import CreateInvoice from './admin/pages/tests/CreateInvoice';
import CreateRewardTax from './admin/pages/tests/CreateRewardTax';
import CreateRewardNoTax from './admin/pages/tests/CreateRewardNoTax';

const FrontendCSS = lazy(() => import('./components/cssloader/FrontendCSS'));
const BackendCSS = lazy(() => import('./components/cssloader/BackendCSS'));

// ADMIN-BACKEND
const AdminHome = lazy(() => import('./admin/pages/Home'));
const CompanyData = lazy(() => import('./admin/pages/CompanyData'));
const Users = lazy(() => import('./admin/pages/Users'));
const AdminObjects = lazy(() => import('./admin/pages/Objects'));
const AdminObjectReservationRequests = lazy(() => import('./admin/pages/subpages/ObjectReservationRequests'));
const AdminInvoices = lazy(() => import('./admin/pages/Invoices'));
const AdminRewards = lazy(() => import('./admin/pages/Rewards'));
const ObjectEquipment = lazy(() => import('./admin/pages/ObjectEquipment'));
const NewObjectEquipment = lazy(() => import('./admin/pages/subpages/NewObjectEquipment'));
const EditObjectEquipment = lazy(() => import('./admin/pages/subpages/EditObjectEquipment'));
const ObjectEquipmentCycles = lazy(() => import('./admin/pages/ObjectEquipmentCycles'));
const NewEquipmentCycle = lazy(() => import('./admin/pages/subpages/NewEquipmentCycle'));
const EditEquipmentCycle = lazy(() => import('./admin/pages/subpages/EditEquipmentCycle'));
const ObjectIncidentalCosts = lazy(() => import('./admin/pages/ObjectIncidentalCosts'));
const NewIncidentalCost = lazy(() => import('./admin/pages/subpages/NewIncidentalCost'));
const EditIncidentalCost = lazy(() => import('./admin/pages/subpages/EditIncidentalCost'));
const ObjectBenefitsCosts = lazy(() => import('./admin/pages/ObjectBenefitsCosts'));
const NewBenefitsCost = lazy(() => import('./admin/pages/subpages/NewBenefitsCost'));
const EditBenefitsCost = lazy(() => import('./admin/pages/subpages/EditBenefitsCost'));
const ObjectLocations = lazy(() => import('./admin/pages/ObjectLocations'));
const NewLocation = lazy(() => import('./admin/pages/subpages/NewLocation'));
const EditLocation = lazy(() => import('./admin/pages/subpages/EditLocation'));
const ObjectPropertyTypes = lazy(() => import('./admin/pages/ObjectPropertyTypes'));
const NewPropertyType = lazy(() => import('./admin/pages/subpages/NewPropertyType'));
const EditPropertyType = lazy(() => import('./admin/pages/subpages/EditPropertyType'));
const GlobalText = lazy(() => import('./admin/pages/GlobalText'));


// USER-BACKEND
const Home = lazy(() => import('./pages/userbackend/Home'));
const UserAccount = lazy(() => import('./pages/userbackend/UserAccount'));
const EditProfile = lazy(() => import('./pages/userbackend/account/EditProfile'));
const ChangePassword = lazy(() => import('./pages/userbackend/account/ChangePassword'));
const UserSettings = lazy(() => import('./pages/userbackend/account/UserSettings'));
const EndMembership = lazy(() => import('./pages/userbackend/account/EndMembership'));
const Objects = lazy(() => import('./pages/userbackend/objects/Objects'));
const NewObject = lazy(() => import('./pages/userbackend/objects/subpages/NewObject'));
const EditObjectGeneralData = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectGeneralData'));
const EditObjectLocationData = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectLocationData'));
const EditObjectEquipmentData = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectEquipmentData'));
const EditObjectPictures = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectPictures'));
const EditObjectPrices = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectPrices'));
const EditObjectIncidentalCosts = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectIncidentalCosts'));
const EditObjectBenefitsCosts = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectBenefitsCosts'));
const EditObjectOccupancies = lazy(() => import('./pages/userbackend/objects/subpages/EditObjectOccupancies'));
const Guests = lazy(() => import('./pages/userbackend/objects/Guests'));
const NewGuest = lazy(() => import('./pages/userbackend/objects/subpages/NewGuest'));
const EditGuest = lazy(() => import('./pages/userbackend/objects/subpages/EditGuest'));
const Requests = lazy(() => import('./pages/userbackend/objects/Requests'));
const Reviews = lazy(() => import('./pages/userbackend/objects/Reviews'));
const Stats = lazy(() => import('./pages/userbackend/objects/Stats'));
const Invoices = lazy(() => import('./pages/userbackend/contractual/Invoices'));
const ContractDurations = lazy(() => import('./pages/userbackend/contractual/ContractDurations'));
const RewardsInfos = lazy(() => import('./pages/userbackend/rewards/Infos'));
const RewardsSettings = lazy(() => import('./pages/userbackend/rewards/Settings'));
const Rewards = lazy(() => import('./pages/userbackend/rewards/Rewards'));
const Error404 = lazy(() => import('./pages/userbackend/Error404'));


const App = () => {

  const [pageLocation, setPageLocation] = useState();

  const dispatch = useDispatch();

  const darkmode = useSelector(state => state.global.darkmode);
  const navstate = useSelector(state => state.global.navstate);
  const user = useSelector(state => state.global.user);
  const adminuser = useSelector(state => state.global.adminuser);
  const userstate = useSelector(state => state.global.userstate);
  const ref = useRef(null);

  const resizingDelayTimer = useRef(null);

  function getClassBySize(size) {
    if (size > 1679) {
      return 'xxl'
    }
    else if (size > 1399 && size < 1680) {
      return 'xl'
    }
    else if (size > 1199 && size < 1400) {
      return 'lg'
    }
    else if (size > 991 && size < 1200) {
      return 'md'
    }
    else if (size > 767 && size < 992) {
      return 'sm'
    }
    else if (size > 0 && size < 768) {
      return 'xs'
    }
    else {
      return 'xxl'
    }
  }

  useEffect(() => {
    const element = ref?.current;
    if (!element) return;
    let startsizeclass = getClassBySize(element.offsetWidth);
    const observer = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        element.setAttribute("class", "app_wrapper");
        let sizeClass = getClassBySize(element.offsetWidth);
        element.classList.add(sizeClass);
        if (sizeClass === 'xs' && user) {
          dispatch(GlobalStore.setNavState('closed'))
        }
        else {
          if (startsizeclass !== sizeClass) {
            dispatch(GlobalStore.setNavState('opened'))
          }
        }
      }, 1)
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ref, dispatch, user, pageLocation])


  // REGISTER / LOGIN
  if (!user) {
    return (
      <Suspense>
        <FrontendCSS>
          <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
            <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
              <Routes>
                <Route path='/' element={<Login />}></Route>
                <Route path='/registrierung' element={<Register />}></Route>
                <Route path='/passwort-vergessen' element={<Password />}></Route>
                <Route path='/mitgliedschaft-beendet' element={<UserEndMembership />}></Route>
                <Route path='*' element={<FrontendError404 />}></Route>
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        </FrontendCSS>
      </Suspense>
    )
  }

  // ADMIN-BACKEND
  if (adminuser && !HelperService.checkSwitchAdminToUser(user, adminuser)) {
    return (
      <div className="app_wrapper" ref={ref}>
        <Suspense>
          <BackendCSS>
            <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
              <div className={"app" + (darkmode ? ' darkmode' : '')} >
                <AdminSidebar />
                <div className={'mainholder ' + navstate}>
                  <AdminTopbar />
                  <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                    <Routes>
                      <Route path='/' element={<AdminHome />}></Route>
                      <Route path='/unternehmensdaten' element={<CompanyData />}></Route>
                      <Route path='/benutzer' element={<Users />}></Route>
                      <Route path='/unterkuenfte' element={<AdminObjects />}></Route>
                      <Route path='/unterkuenfte/reservierungsanfragen/:id' element={<AdminObjectReservationRequests />}></Route>
                      <Route path='/rechnungen' element={<AdminInvoices />}></Route>
                      <Route path='/belohnungen' element={<AdminRewards />}></Route>
                      <Route path='/ausstattungsmerkmale' element={<ObjectEquipment />}></Route>
                      <Route path='/ausstattungsmerkmale/neu' element={<NewObjectEquipment />}></Route>
                      <Route path='/ausstattungsmerkmale/bearbeiten/:id' element={<EditObjectEquipment />}></Route>
                      <Route path='/abrechnungsoptionen' element={<ObjectEquipmentCycles />}></Route>
                      <Route path='/abrechnungsoptionen/neu' element={<NewEquipmentCycle />}></Route>
                      <Route path='/abrechnungsoptionen/bearbeiten/:id' element={<EditEquipmentCycle />}></Route>
                      <Route path='/nebenkostenoptionen' element={<ObjectIncidentalCosts />}></Route>
                      <Route path='/nebenkostenoptionen/neu' element={<NewIncidentalCost />}></Route>
                      <Route path='/nebenkostenoptionen/bearbeiten/:id' element={<EditIncidentalCost />}></Route>
                      <Route path='/zusatzkostenoptionen' element={<ObjectBenefitsCosts />}></Route>
                      <Route path='/zusatzkostenoptionen/neu' element={<NewBenefitsCost />}></Route>
                      <Route path='/zusatzkostenoptionen/bearbeiten/:id' element={<EditBenefitsCost />}></Route>
                      <Route path='/lagebeschreibungen' element={<ObjectLocations />}></Route>
                      <Route path='/lagebeschreibungen/neu' element={<NewLocation />}></Route>
                      <Route path='/lagebeschreibungen/bearbeiten/:id' element={<EditLocation />}></Route>
                      <Route path='/objektarten' element={<ObjectPropertyTypes />}></Route>
                      <Route path='/objektarten/neu' element={<NewPropertyType />}></Route>
                      <Route path='/objektarten/bearbeiten/:id' element={<EditPropertyType />}></Route>
                      <Route path='/globale-texte' element={<GlobalText />}></Route>
                      <Route path='/tests/rechnung' element={<CreateInvoice />}></Route>
                      <Route path='/tests/gutschrift' element={<CreateRewardTax />}></Route>
                      <Route path='/tests/gutschrift2' element={<CreateRewardNoTax />}></Route>
                      <Route path='*' element={<Error404 />}></Route>
                    </Routes>
                  </ErrorBoundary>
                </div>
              </div>
            </BrowserRouter>
          </BackendCSS>
        </Suspense>
      </div>
    )
  }

  // USER INCOMPLETE
  if (user && userstate === 'incomplete') {
    return (
      <div className="app_wrapper" ref={ref}>
        <Suspense>
          <FrontendCSS>
            <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
              <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                <Routes>
                  <Route path='/' element={<NoCompleteUserdata />}></Route>
                  <Route path='*' element={<FrontendError404 />}></Route>
                </Routes>
              </ErrorBoundary>
            </BrowserRouter>
          </FrontendCSS>
        </Suspense>
      </div>
    )
  }

  // USER-BACKEND VERMITTLER
  if (user?.data?.userType === 'referrer') {
    return (
      <div className="app_wrapper" ref={ref}>
        <Suspense>
          <BackendCSS>
            <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
              <div className={"app" + (darkmode ? ' darkmode' : '')} >
                <Sidebar />
                <div className={'mainholder ' + navstate}>
                  <Topbar />
                  <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                    <Routes>
                      <Route path='/' element={<Rewards />}></Route>
                      <Route path='/mein-konto' element={<UserAccount />}></Route>
                      <Route path='/mein-konto/profil-bearbeiten' element={<EditProfile />}></Route>
                      <Route path='/mein-konto/passwort-aendern' element={<ChangePassword />}></Route>
                      <Route path='/mein-konto/einstellungen' element={<UserSettings />}></Route>
                      <Route path='/mein-konto/mitgliedschaft-beenden' element={<EndMembership />}></Route>
                      <Route path='/informationen' element={<RewardsInfos />}></Route>
                      <Route path='/einstellungen' element={<RewardsSettings />}></Route>
                      <Route path='*' element={<Error404 />}></Route>
                    </Routes>
                  </ErrorBoundary>
                </div>
              </div>
            </BrowserRouter>
          </BackendCSS>
        </Suspense>
      </div>
    )
  }


  // USER-BACKEND VERMIETER
  return (
    <div className="app_wrapper" ref={ref}>
      <Suspense>
        <BackendCSS>
          <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
            <div className={"app" + (darkmode ? ' darkmode' : '')} >
              <Sidebar />
              <div className={'mainholder ' + navstate}>
                <Topbar />
                <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                  <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/mein-konto' element={<UserAccount />}></Route>
                    <Route path='/mein-konto/profil-bearbeiten' element={<EditProfile />}></Route>
                    <Route path='/mein-konto/passwort-aendern' element={<ChangePassword />}></Route>
                    <Route path='/mein-konto/einstellungen' element={<UserSettings />}></Route>
                    <Route path='/mein-konto/mitgliedschaft-beenden' element={<EndMembership />}></Route>
                    <Route path='/unterkuenfte' element={<Objects />}></Route>
                    <Route path='/unterkuenfte/neu' element={<NewObject />}></Route>
                    <Route path='/unterkuenfte/:id/allgemeine-angaben' element={<EditObjectGeneralData />}></Route>
                    <Route path='/unterkuenfte/:id/lage' element={<EditObjectLocationData />}></Route>
                    <Route path='/unterkuenfte/:id/ausstattung' element={<EditObjectEquipmentData />}></Route>
                    <Route path='/unterkuenfte/:id/bilder' element={<EditObjectPictures />}></Route>
                    <Route path='/unterkuenfte/:id/preise' element={<EditObjectPrices />}></Route>
                    <Route path='/unterkuenfte/:id/preise/nebenkosten' element={<EditObjectIncidentalCosts />}></Route>
                    <Route path='/unterkuenfte/:id/preise/zusatzkosten' element={<EditObjectBenefitsCosts />}></Route>
                    <Route path='/unterkuenfte/:id/belegungskalender' element={<EditObjectOccupancies />}></Route>
                    <Route path='/gaeste' element={<Guests />}></Route>
                    <Route path='/gaeste/neu' element={<NewGuest />}></Route>
                    <Route path='/gaeste/bearbeiten/:id' element={<EditGuest />}></Route>
                    <Route path='/anfragen' element={<Requests />}></Route>
                    <Route path='/bewertungen' element={<Reviews />}></Route>
                    <Route path='/statistiken' element={<Stats />}></Route>
                    <Route path='/rechnungen' element={<Invoices />}></Route>
                    <Route path='/laufzeiten' element={<ContractDurations />}></Route>
                    <Route path='/belohnungssystem/informationen' element={<RewardsInfos />}></Route>
                    <Route path='/belohnungssystem/belohnungen' element={<Rewards />}></Route>
                    <Route path='/belohnungssystem/einstellungen' element={<RewardsSettings />}></Route>
                    <Route path='*' element={<Error404 />}></Route>
                  </Routes>
                </ErrorBoundary>
              </div>
            </div>
          </BrowserRouter>
        </BackendCSS>
      </Suspense>
    </div>
  );

}

export default App;
